#startBtn {
    margin-top: 100px; 
}

#modalBack {
    width: 100%;
    height: 100px;

}

#thing {
    
}