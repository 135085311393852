#waveThing {
    width: 100%;
    /* height: 500px; */
}

h1 {
    font-size: 5em;
    margin-top: 0px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

    /* background-color: purple; */
}

#topBar {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
}

h2 {
    text-align: center;
    font-size: 2em;
    font-style: italic;

}

#fillerThing {
    height: 200px;
    border: solid;
    border-color: #570df8;
    margin-bottom: 0px;
}

img {
    text-align: center;
    margin-left: 20px;
    width: 20%;
}

#row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#secondP {
    margin-top: 100px;
}

#firstP {
    margin-bottom: 100px;
}

#name {
    text-align: center;
}

#cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    margin-left: 20px;
    grid-gap: 0px;
}
/*
.card {
    display: flex;
    align-items: center;
    justify-content: center;
    
} */

p {
    padding: 20px;
}